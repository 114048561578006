<template>
    <v-app>
        <div class="frame-popup">
            <header>
                <h1>업무VMS(Voice Message Service)</h1>
                <div class="is-right">
                    <v-btn class="jh-btn is-icon" title="닫기" @click="close"><i class="jh-icon-close-lg is-white"></i></v-btn>
                </div>
            </header>
            <main>
                <div class="jh-form-wrap">
                    <div class="jh-ui-header">
                        <h3>음성 메시지(VMS) 내용</h3>
                    </div>
                    <textarea class="jh-form is-bg-white" style="height: 185px;" readonly v-model="contents"></textarea>
                    <div class="jh-btn-area"><v-btn class="jh-btn is-md is-blue" @click="play" >재생호출</v-btn></div>
                </div>

                <div class="jh-form-wrap is-bg-light-blue is-border-blue is-mt-10">
                    <div class="jh-align-middle jh-align-center">
                        <span class="jh-label">수신결과</span>
                        <input type="text" class="jh-form is-md is-bg-white" style="width: 200px;" v-model="resultMsg" readonly>
                        <button type="button" class="jh-btn is-md is-main is-ml-10" @click="result">결과확인</button>
                    </div>
                </div>
            </main>
            <footer><v-btn class="jh-btn is-md" @click="close">확인</v-btn></footer>
        </div>
    </v-app>
</template>

<script>
import api from '../../store/apiUtil.js';
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
import { mapGetters } from "vuex";

export default {
    name: "MENU_P100701", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    props: {
    },
    components: {
        dataTables
    },
    data() {
        return {
            cd: '',
            contents: '',
            resultMsg: '',

            callId: '',
            custNo: '',

            PLAY_YN: false, // 재생호출 여부
        };
    },
    created() {
        this.getUrlParam();
    },
    mounted(){
        // 토큰 동기화를 위해 메인창에 window 객체에 팝업 windows 객체 저장
        // window 팝업에서 palette api를 호출할 경우 필수
        if (opener) {
            if (!opener.popups) opener.popups = [];
                opener.popups.push(self);
        }
        if(opener) {
            console.log("opener is valid!!!");
            console.log(this.$store.getters["callStore/GE_PLAY_YN"]);
            // this.$store.commit('callStore/MU_PLAY_YN', 'N');
            opener.g_winPopup = this;
        }

        this.getContents(this.cd);
    },
    computed: {
        ...mapGetters({
            geUserRole: 'userStore/GE_USER_ROLE',
            geCallStt: 'callStore/GE_CALL_STATE',
            geCnslSaveYn: 'callStore/GE_CNSL_SAVE_YN',
            gePlayYN: 'callStore/GE_PLAY_YN',
        }),
    },
    watch: {
    },
    methods: {
        close(){
            self.close();
        },
        getUrlParam() {
            const param = new URLSearchParams(location.search);

            const cd = param.get('cd');
            const callId = param.get('callId');
            const custNo = param.get('custNo');

            this.cd = cd;
            this.callId = callId;
            this.custNo = custNo;
        },
        async getContents(cd) {
            const requestData  = {
                headers: {
                    SERVICE: 'setting.system.cmmn-code-manage',
                    METHOD: 'inquire',
                    TYPE: 'select',
                },
                sendData:{}
            };

            // header 세팅
            requestData.headers["URL"] = "/api/setting/system/cmmn-code-manage/detail";
            requestData.headers["METHOD"] = "list";
            requestData.headers["ASYNC"] = false;
            requestData.headers["ROW_CNT"] = 10000;
            requestData.headers["PAGES_CNT"] = 1;
            // sendData 세팅
            requestData.sendData["GROUP_CD"] = 'VW01';

            const response = await this.common_postCall(requestData);
            const data = response.DATA;

            data.forEach(item => {
                if (item.CD === this.cd) {
                    this.contents = item.CD_XPLN;
                }
            })
        },
        play() {
            // 통화중일경우에만 vms 재생호출 실행
            console.log('***** custNo *****')
            console.log(this.custNo);

            if (this.custNo == '' || this.custNo == undefined || this.custNo == 'undefined') {
                this.common_alert(`고객정보가 없습니다.`);
            } else {
                if (this.gePlayYN == 'N') {
                    // if(this.geCallStt == 'TALKING') {
                    //     this.PLAY_YN = true;
                    //     this.resultMsg = '';
                    //     this.saveRcvData();
                    //     this.sendMsgToParent("CONSULT", this.cd, 'Y');
                    // } else {
                    //     this.common_alert(`통화중 상태에서만 업무 VMS \n 요청이 가능합니다.`, 'info');
                    // }
                    this.PLAY_YN = true;
                    this.resultMsg = '';
                    this.saveRcvData();
                    this.sendMsgToParent("CONSULT", this.cd, 'Y');
                } else if (this.gePlayYN == 'Y') {
                    this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요.`);
                }
            }
        },
        async saveRcvData() {

            const requestData  = {
                headers: {
                    SERVICE: "phone.main.ivrInpt",
                    METHOD: "inqire",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/phone/main/ivrInpt/save",
                },
                sendData:{}
            };

            // header 세팅
            requestData.sendData["INLNE_NO"] = this.geUserRole.IPCC_NO;
            requestData.sendData["INPT_NUM"] = null;
            requestData.sendData["AGR_YN"] = null;
            requestData.sendData["VAR1"] = 'V';
            requestData.sendData["VAR2"] = 'E3';
            requestData.sendData["VAR3"] = null;
            requestData.sendData["VAR4"] = null;
            requestData.sendData["VAR5"] = this.cd; // VMS코드값
            requestData.sendData["CUST_NO"] = this.custNo;
            requestData.sendData["CALL_ID"] = this.callId;
            requestData.sendData["REG_ID"] = this.geUserRole.userId;
            requestData.sendData["UPD_ID"] = this.geUserRole.userId;

            const response = await this.common_postCall(requestData);

            console.log(response);

        },
        result() {
            if (this.gePlayYN == 'N') {
                api
                .post(
                    "/api/phone/main/ivrRcv/inqire", //api url입력
                    {
                        INLNE_NO: this.geUserRole.IPCC_NO,
                        CALL_ID: this.callId,
                        CUST_NO: this.custNo,
                        VAR1: 'V',
                        // VAR2: "Y",
                        VAR5: this.cd,
                        REG_ID: this.geUserRole.userId,
                    },
                    {
                        head: {
                            SERVICE: "phone.main.ivrRcv",
                            METHOD: "inqire",
                            TYPE: "BIZ_SERVICE",
                        },
                    }
                )
                .then((response) => {
                    if (this.mixin_isEmpty(response.data.DATA) || response.data.HEADER.ERROR_FLAG) {
                        let errMsg = response.data.HEADER.ERROR_MSG;
                        this.common_toast(errMsg, "error");
                        return false;
                    } else {
                        console.log(response.data.DATA[0]);
                        let data = response.data.DATA[0];
    
                        const var2 = data.VAR2;
                        const agrYN = data.AGR_YN;
    
                        // E0: 정상
                        // E1:고객 입력 없음
                        // E2:고객 지시대로 안누르고 잘못 누른경우
                        // E3: 콜종료 - 초기값
                        // E4: ivr 시작시 셋팅 값
                            
                        if (var2 == 'E0') { // 정상
                            if(agrYN == 'Y') {
                                this.resultMsg = '업무처리 가능';
                            } else if (agrYN == 'N') {
                                this.resultMsg = '업무처리 불가';
                            }
                        } else {    // 그 외
                            this.resultMsg = '입력값이 없습니다.';
                        }
                    }
                })
                .catch((err) => {
                    this.common_toast(err);
                });
            } else if (this.gePlayYN == 'Y') {
                this.common_alert(`음성 멘트 실행중 입니다. \n잠시 후 시도하세요.`);
            }
        },
        sendMsgToParent(msg, val, plag) {
            this.sendToOpener({
                evt:  msg,
                Val1: val,
                message: 'P100701',
                plagYN: plag,
            });
        },
        sendToOpener( sendObj ) {
            if( opener == null) {
                return;
            }
            let sendStr = JSON.stringify(sendObj);
            window.opener.postMessage( sendStr, '*');
        },
        calledFromOpener( evt ) {
            console.log("receiveFromOpener -------");
            this.$store.dispatch("callStore/AC_PLAY_YN", evt.msg);
            if(evt.msg == 'N') {
                this.result();
            }
        },
    },
};
</script>

<style></style>